$content-width: 1140px;
$content-width-mobile: 100vw;

@import "../../../node_modules/swiper/swiper";
@import "../../../node_modules/swiper/modules/pagination/pagination";
@import "../../../node_modules/swiper/modules/navigation/navigation";
@import "../../../node_modules/swiper/modules/autoplay/autoplay";

body,
html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

.page-home nav.nav-wrap {
  height: 85px;
  background: #ffffff;
  width: 100vw;
  z-index: 970;
  box-shadow: 0 5px 8px #00000026;
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;

  .nav {
    margin: 0 auto;
    width: $content-width;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 1140px) {
      width: $content-width;
    }

    @media screen and (max-width: 1140px) {
      width: $content-width-mobile;
      padding: 0 30px;
    }

    .logo {
      margin: 15px 0;
    }

    .menu {
      background: transparent;
      color: #262220;
      line-height: 70px;
      display: -ms-flexbox;
      display: flex;
      align-items: center;

      .item {
        float: left;
        text-align: center;
        width: 120px;
        font-size: 14px;
        color: #262220;
        border: none;
        padding: 0;

        @media screen and (max-width: 480px) {
          font-size: 12px;
          width: 60px;
        }
      }
    }
  }
}

.page-home > .content {
  width: 100vw;
  height: 980px;
  position: relative;
  text-align: center;
  border-color: #666;
  background-color: #F6F3F1;
  background-repeat: no-repeat;
  background-image: url('../../images/decorates/decorate-1.png'),
  url('../../images/decorates/decorate-2.png'),
  url('../../images/decorates/decorate-3.png'),
  url('../../images/decorates/decorate-4.png');
  background-size: 412px 318px, 614px 470px, 600px 470px, 215px 348px;
  background-position: top -80px left 186px, left 0 bottom 0, right 0 bottom 0, right 0 bottom 50%;

  &:before {
    background-size: cover;
    background-position: 50%;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 771px;
    content: "";
    z-index: -1;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    height: 150px;
    width: $content-width;
    background-position: 125px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;

    @media screen and (min-width: 1140px) {
      width: $content-width;
    }

    @media screen and (max-width: 1140px) {
      width: $content-width-mobile;
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;
      height: auto;
    }

    > * {
      @media screen and (max-width: 800px) {
        margin: 10px 0;
      }
    }

    .image {
      background: url("../../images/logo.png") no-repeat center;
      background-size: contain;
      height: 150px;
      width: 150px;

      @media screen and (min-width: 800px) {
        margin-left: 20px;
      }

      @media screen and (max-width: 800px) {
        height: 120px;
        width: 120px;
      }
    }

    .slogan {
      flex-grow: 1;
      color: #fff;
      font-style: italic;
      font-size: 18px;
      white-space: nowrap;
      @media screen and (max-width: 1140px) {
        font-size: 16px;
      }

      @media screen and (max-width: 900px) {
        font-size: 12px;
      }

      @media screen and (max-width: 800px) {
        flex-grow: 0;
      }
    }

    .download {
      color: #fff;
      background-color: #84542e;
      border-radius: 10px;
      width: 150px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      cursor: pointer;

      @media screen and (max-width: 800px) {
        height: 40px;
        width: 120px;
      }
    }
  }

  .app {
    flex-grow: 1;
    width: $content-width;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1140px) {
      width: $content-width-mobile;
    }

    .app-info {
      display: flex;
      flex-direction: column;

      .app-introduce {
        width: 532px;
        height: 225px;
      }

      .scan {
        width: 370px;
        height: 145px;
        margin-top: 75px;
        background-image: url("../../images/border.png");
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .qrcode {
          height: 111px;
          width: 111px;
          background: url("https://acupoint-attach.oss-cn-shenzhen.aliyuncs.com/images/portal/download-qrcode.png") center no-repeat;
          background-size: contain;
          @media screen and (max-width: 800px) {
            display: none;
          }
        }

        .scan-tip {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 29px;
            height: 29px;
          }

          h3 {
            font-size: 20px;
            font-weight: bold;
            margin: 2px 0;
            color: #262220;
          }

          span {
            font-size: 15px;
            color: #6B6461;
          }
        }
      }

      .downloads {
        display: flex;

        .download-button {
          width: 150px;
          height: 50px;
          color: #262220;
          background-image: url("../../images/button_back.png");
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          margin-right: 20px;

          .anticon {
            font-size: 20px;
          }

          .button-text {
            font-size: 14px;
          }
        }
      }
    }

    .app-video {
      width: 300px;
      height: 670px;
      border: solid 3px #262220;
      border-radius: 32px;
      margin-left: 160px;
      overflow: hidden;
    }
  }
}

.page-home .sub-content {
  height: 760px;
}

.page-home .introduce {
  display: flex;
  align-items: center;
  justify-content: center;

  .introduce-no {
    width: 88px;
    height: 66px;
    background-size: cover;
    margin-bottom: -10px;
  }

  .no1 {
    background-image: url("../../images/introduces/no-01.png");
  }

  .no2 {
    background-image: url("../../images/introduces/no-02.png");
  }

  .no3 {
    background-image: url("../../images/introduces/no-03.png");
  }

  .no4 {
    background-image: url("../../images/introduces/no-04.png");
  }

  h1 {
    color: #262220;
    font-size: 42px;
    font-weight: bold;
    margin: 0;
  }

  .introduce-text {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-size: 17px;
      color: #262220;
      line-height: 34px;
    }
  }


  .quxue-video {
    position: relative;
    width: 650px;
    height: 500px;

    > img, > div {
      border: 3px white solid;
      border-radius: 4px;
      overflow: hidden;
    }

    > img {
      width: 420px;
      height: 270px;
      position: absolute;
      top: 70px;
      left: 15px;
    }

    > div {
      position: absolute;
      top: 200px;
      left: 90px;
    }
  }
}

.page-home {
  .introduce-two, .introduce-four {
    background-color: #F6F3F1;
    flex-direction: row-reverse;

    .introduce-text {
      align-items: end;
    }
  }
}

.page-home .introduce-title {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 42px;
    font-weight: bold;
    margin: 0 24px;
  }
}

.page-home {
  .more-fun, .medicines {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.page-home .more-fun {

  .more-fun-list {
    display: flex;
    width: 100%;
    max-width: 1100px;
    justify-content: space-around;

    .more-fun-item > p {
      text-align: center;
      margin-top: 40px;
      font-size: 15px;
      color: #262220;
    }
  }
}

.page-home .medicines {
  background-color: #F6F3F1;
}

.page-home .to-medicines {
  border: #DCD1BF 1px solid;
  padding: 7px 18px;
  color: #BCA98A;
  font-size: 18px;
  margin-top: 20px;
}

.page-home .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 187px;
  background-color: #392F2A;
  color: rgb(184, 184, 184);

  > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1920px;

    .contact {
      display: flex;
      flex-direction: column;

      span {
        margin: 7px 0;
      }
    }

    .scan {
      display: flex;

      .qrcode {
        height: 110px;
        width: 110px;
        background: url("https://acupoint-attach.oss-cn-shenzhen.aliyuncs.com/images/portal/download-qrcode.png") center no-repeat;
        background-size: contain;
        @media screen and (max-width: 800px) {
          display: none;
        }
      }

      .downloads {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 30px;
      }
    }
  }

  > p {
    text-align: center;

     a {
       color: #B8B8B8;
     }
  }

  @media screen and (max-width: 800px) {
    height: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}


.zhiyuan {
  .slogan {
    width: 100%;
  }

  .app-info {
    max-width: 1024px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 114px 0;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 520px;

      .app-name {
        font-size: 24px;
        font-weight: bold;
        margin: 16px 0 30px;
      }

      .description {
        font-size: 17px;
        color: #262220;
      }

      button {
        border-radius: 4px;
        border-color: #C1A16B;
        color: #C1A16B;
        padding: 14px 24px;
        height: auto;
        margin-top: 20px;
      }
    }
  }
}
@font-face {
  font-family: 'custom';
  src: url("../../fonts/custom.ttf")
}

.feedback {
  max-width: 500px;
  margin: 0.5em auto 0 auto;
  font-family: 'custom';

  * {
    box-shadow: none !important;
  }

  & > div {
    margin: 0 15px;
  }

  .tip {
    color: #6b6461;
    font-size: 12px;
  }

  &-btn {
    width: 100%;
  }

  .ant-radio-group {
    & > * {
      margin: 4px !important;
      border-radius: 4px;
    }

    .ant-radio-button-wrapper, .ant-radio-button-wrapper-checked, .ant-radio-button-wrapper:hover {
      background: #f3f0ec;
      margin: 0 4px;
      padding: 0 20px;
      border: none;
    }

    .ant-radio-button-wrapper-checked, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      background: #b39158 !important;
    }

    .ant-radio-button-wrapper:hover {
      color: #b39158;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: rgba(255, 255, 255, 0);
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      color: #fff;
    }
  }

  .ant-input {
    background: #f3f0ec;
  }

  .ant-input:hover {
    border-color: #b39158;
  }

  .ant-input:focus {
    border-color: #b39158;
    box-shadow: 0 0 0 2px rgba(179, 145, 88, 0.2);
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .feedback-btn {
    color: #3b3b3b;
    background: linear-gradient(90deg, rgb(226, 207, 164), #b39158) no-repeat;
    border: none;

    &:after {
      box-shadow: 0 0 0 2px rgba(179, 145, 88, 0.2) !important;
    }
  }
}
